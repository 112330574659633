import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,396.000000) scale(0.100000,-0.100000)">
          <path d="M2950 2472 c0 -22 6 -32 20 -37 45 -14 48 -33 53 -355 4 -294 5 -308
30 -380 40 -119 49 -143 63 -172 8 -14 14 -33 14 -41 0 -18 21 -27 61 -27 l29
0 0 338 0 338 -25 22 c-27 24 -32 42 -12 42 6 1 101 0 211 0 216 -1 245 -7
299 -58 49 -46 67 -93 67 -172 0 -79 -18 -126 -67 -172 -49 -47 -91 -58 -223
-58 l-120 0 0 -140 0 -140 108 0 c180 1 303 41 388 126 86 86 114 180 114 389
0 264 -53 381 -209 459 -106 54 -142 59 -483 63 l-318 5 0 -30z"/>
<path d="M4150 2173 c0 -5 7 -17 15 -27 12 -16 15 -57 15 -198 l0 -178 35 0
35 0 0 64 0 63 78 5 c125 7 167 43 167 143 -1 104 -55 135 -230 135 -63 0
-115 -3 -115 -7z m248 -73 c30 -28 30 -86 0 -117 -18 -19 -32 -23 -85 -23
l-63 0 0 80 0 80 63 0 c50 0 68 -4 85 -20z"/>
<path d="M4510 2171 c0 -5 7 -14 15 -21 12 -10 15 -45 15 -196 l0 -184 35 0
35 0 0 70 0 70 44 0 c43 0 45 -1 85 -64 23 -35 49 -67 57 -70 22 -9 74 -7 74
2 0 4 -22 39 -48 77 l-47 70 39 37 c38 37 38 38 34 97 -5 70 -23 96 -77 111
-44 12 -261 13 -261 1z m249 -77 c43 -55 16 -107 -60 -118 -75 -10 -81 -6 -85
55 -5 84 -2 89 66 89 51 0 61 -3 79 -26z"/>
<path d="M4970 2162 c-19 -9 -45 -32 -57 -51 -20 -30 -23 -47 -23 -135 0 -84
4 -107 20 -134 59 -97 281 -97 340 0 16 27 20 50 20 134 0 88 -3 105 -23 135
-33 50 -78 69 -167 69 -51 0 -86 -6 -110 -18z m207 -76 c20 -24 23 -38 23
-107 0 -44 -5 -89 -10 -100 -35 -65 -181 -64 -215 2 -9 17 -15 56 -15 101 0
112 28 140 134 135 50 -3 63 -8 83 -31z"/>
<path d="M5290 2173 c0 -4 7 -17 15 -27 12 -16 15 -57 15 -199 l0 -179 118 4
c144 5 185 21 220 89 20 38 23 55 20 130 -3 69 -8 91 -27 117 -40 56 -71 66
-223 70 -76 2 -138 0 -138 -5z m286 -86 c21 -25 24 -38 24 -108 0 -44 -5 -89
-11 -101 -16 -29 -70 -48 -139 -48 l-60 0 0 146 0 146 81 -4 c74 -3 83 -6 105
-31z"/>
<path d="M5847 2173 c-5 -8 -139 -332 -158 -380 -8 -21 -6 -23 30 -23 35 0 39
3 57 43 l19 42 87 3 88 3 22 -46 c20 -42 25 -45 60 -45 21 0 38 2 38 4 0 2
-37 93 -83 202 l-83 199 -36 3 c-20 2 -38 -1 -41 -5z m75 -176 c15 -39 28 -73
28 -74 0 -2 -30 -3 -66 -3 -49 0 -65 3 -61 13 44 111 60 147 65 142 3 -3 18
-38 34 -78z"/>
<path d="M6040 2150 l0 -30 70 0 70 0 0 -176 0 -175 38 3 37 3 3 173 2 172 65
0 65 0 0 30 0 30 -175 0 -175 0 0 -30z"/>
<path d="M6502 2163 c-8 -14 -130 -306 -159 -380 -4 -9 7 -13 35 -13 37 0 40
2 56 45 l18 45 89 0 90 0 19 -45 c18 -43 21 -45 60 -45 22 0 40 1 40 3 0 1
-38 92 -84 202 l-84 200 -35 3 c-26 2 -38 -2 -45 -15z m78 -163 c16 -41 30
-76 30 -77 0 -2 -30 -3 -66 -3 -49 0 -65 3 -61 13 41 103 60 147 63 145 2 -2
17 -37 34 -78z"/>
<path d="M6830 2173 c-8 -3 -27 -17 -42 -31 -22 -20 -28 -35 -28 -68 0 -23 3
-49 6 -58 10 -25 67 -55 125 -66 98 -18 129 -41 114 -83 -16 -41 -122 -47
-199 -11 -32 16 -38 16 -46 4 -30 -47 20 -81 127 -88 130 -8 193 33 193 126 0
53 -36 88 -109 107 -124 33 -136 39 -139 64 -3 15 3 30 14 38 24 17 102 16
154 -2 23 -8 44 -15 45 -15 2 0 8 13 13 29 10 28 9 30 -29 45 -38 15 -166 21
-199 9z"/>
<path d="M7090 2166 c0 -8 6 -16 13 -18 8 -3 13 -46 17 -150 4 -128 8 -149 25
-168 34 -37 76 -53 140 -53 64 0 106 16 140 53 18 20 20 39 23 186 l3 164 -35
0 -36 0 0 -134 c0 -73 -5 -148 -11 -166 -16 -44 -66 -66 -116 -49 -52 17 -63
53 -63 203 0 114 -2 126 -19 136 -29 15 -81 13 -81 -4z"/>
<path d="M7490 2168 c0 -7 7 -22 15 -32 12 -16 15 -56 15 -193 l0 -173 98 0
c53 0 115 5 137 10 98 22 128 139 53 200 -19 15 -19 16 1 34 23 21 29 83 11
115 -19 36 -82 51 -210 51 -90 0 -120 -3 -120 -12z m252 -65 c22 -20 23 -41 1
-65 -13 -14 -31 -18 -85 -18 l-68 0 0 50 0 50 67 0 c48 0 72 -5 85 -17z m4
-151 c13 -9 34 -46 34 -62 0 -6 -11 -22 -25 -35 -21 -22 -33 -25 -95 -25 l-70
0 0 65 0 65 73 0 c39 0 77 -4 83 -8z"/>
<path d="M3350 1970 l0 -130 95 0 c144 0 185 29 185 130 0 101 -41 130 -185
130 l-95 0 0 -130z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
